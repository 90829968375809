// Data sevice
import axios from 'axios'
import authHeader from './auth-header'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const API_URL = process.env.VUE_APP_API_URL;

class ChatService {

    index() {
        return axios.get(API_URL + 'chats', { headers: authHeader() })
    }
    show(id) {
        return axios.get(API_URL + 'chats/' + id, { headers: authHeader() })
    }
    store(resource) {
        return axios.post(API_URL + 'chats', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(API_URL + 'chats/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(API_URL + 'chats/' + resource.id, { headers: authHeader() })
    }

    // specific
    getOrCreateChat(resource) {
        return axios.post(API_URL + 'get-or-create-chat', resource, { headers: authHeader() })
    }

    sendMessages(resource) {
        return axios.post(API_URL + 'messages/send', resource, { headers: authHeader() })
    }

    getMessages(resource) {
        return axios.post(API_URL + 'messages', resource, { headers: authHeader() })
    }

    getUserChats(id) {
        return axios.get(API_URL + 'user-chats/' + id, { headers: authHeader() })
    }
    
    getChatMessages(id) {
        return axios.get(API_URL + 'chat-messages/' + id, { headers: authHeader() })
    }

    MarkChatMessagesRead(id) {
        return axios.get(API_URL + 'mark-message-as-read/' + id, { headers: authHeader() })
    }
}

export default new ChatService()