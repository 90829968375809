const state = {
    unreadMessageCount: 0,
    createdChat: null,
};

const mutations = {
    SET_UNREAD_MESSAGE_COUNT(state, count) {
        state.unreadMessageCount = count;
    },

    INCREMENT_UNREAD_MESSAGE_COUNT(state) {
        state.unreadMessageCount++;
    },

    DECREMENT_UNREAD_MESSAGE_COUNT(state) {
        if (state.unreadMessageCount > 0) {
            state.unreadMessageCount--;
        }
    },

    RESET_UNREAD_MESSAGE_COUNT(state) {
        state.unreadMessageCount = 0;
    },

    SET_CREATED_CHAT(state, chat) {
        state.createdChat = chat;
    },

    CLEAR_CREATED_CHAT(state) {
        state.createdChat = null;
    },
};

const actions = {
    setUnreadMessageCount({ commit }, count) {
        commit('SET_UNREAD_MESSAGE_COUNT', count);
    },

    incrementUnreadMessageCount({ commit }) {
        commit('INCREMENT_UNREAD_MESSAGE_COUNT');
    },

    decrementUnreadMessageCount({ commit }) {
        commit('DECREMENT_UNREAD_MESSAGE_COUNT');
    },

    resetUnreadMessageCount({ commit }) {
        commit('RESET_UNREAD_MESSAGE_COUNT');
    },

    setCreatedChat({ commit }, chat) {
        commit('SET_CREATED_CHAT', chat);
    },

    clearCreatedChat({ commit }) {
        commit('CLEAR_CREATED_CHAT');
    },
};

const getters = {
    getUnreadMessageCount: (state) => state.unreadMessageCount,
    getCreatedChat: (state) => state.createdChat,
};

export const chat = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
