import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import authComponent from '@/components/auth/authComponent.vue'
import LoginPage from '@/components/auth/LoginPage.vue'
import ForgottenPassword from '@/components/auth/ForgottenPassword.vue'
import ResetPassword from '@/components/auth/ResetPassword.vue'
import VerifyEmail from '@/components/auth/VerifyEmail.vue'
import VerifyUser from '@/components/auth/VerifyUser.vue'
import RegisterComponent from '@/components/auth/RegisterComponent.vue'
import GuestDashboard from '@/components/Dashboard/GuestDashboard.vue'
import AdminDashboard from '@/components/Dashboard/AdminDashboard.vue'
import TermsComponent from '@/components/auth/TermsComponent.vue'
import PolicyComponent from '@/components/auth/PolicyComponent.vue'
import ListingsView from '@/views/ListingsView.vue'
import UsersView from '@/views/UsersView.vue'
import DashboardView from '@/views/DashboardView.vue'
import CategoriesView from '@/views/CategoriesView.vue'
import SubcategoriesView from '@/views/SubcategoriesView.vue'
import GroupsView from '@/views/GroupsView.vue'
import RolesView from '@/views/RolesView.vue'
import PermissionsView from '@/views/PermissionsView.vue'
import CountriesView from '@/views/CountriesView.vue'
import CitiesView from '@/views/CitiesView'
import TransactionsView from '@/views/TransactionView.vue'
import PlaceAd from '@/views/PlaceAd.vue'
import ListingCost from '@/views/ListingCostView.vue'
import AdminLoginPage from '@/components/auth/AdminLoginPage.vue'
import ProductView from '@/views/ProductView.vue'
import NotificationTest from '@/views/NotificationTest.vue'
import MyNotifications from '@/views/MyNotifications.vue'
import ChatView from '@/views/ChatView.vue'
import MyAds from '@/views/MyAds.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth-list',
    name: 'Aunthentication',
    component: authComponent,
    props: false,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    props: false,
  },
  {
    path: '/admin-login',
    name: 'AdminLoginPage',
    component: AdminLoginPage,
    props: false,
  },
  {
    path: '/forgotten-password',
    name: 'ForgottenPassword',
    component: ForgottenPassword,
    props: false,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    props: false,
  },
  {
    path: '/product',
    name: 'ProductView',
    component: ProductView,
    props: false,
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/ListView.vue'),
    props: (route) => ({ query: route.query })
  },
  {
    path: '/create-listing/:categoryName',
    name: 'CreateListing',
    component: () => import('@/views/CreateListing.vue'),
    props: true
  },
  {
    path: '/login/:provider',
    name: 'SocialOauthCallback',
    component: () => import('@/components/auth/SocialOauthCallback.vue'),
    props: true
  },
  {
    path: '/verify',
    name: 'VerifyEmail',
    component: VerifyEmail,
    props: false,
  },
  {
    path: '/verify-user',
    name: 'VerifyUser',
    component: VerifyUser,
    props: false,
  },
  {
    path: '/place-ad',
    name: 'PlaceAd',
    component: PlaceAd,
    props: false,
  },
  {
    path: '/my-ads',
    name: 'MyAds',
    component: MyAds,
    props: false,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterComponent,
    props: false,
  },
  {
    path: '/terms',
    name: 'term',
    component: TermsComponent,
    props: false,
  },
  {
    path: '/policies',
    name: 'policies',
    component: PolicyComponent,
    props: false,
  },
  {
    path: '/dashboard',
    name: 'GuestDashboard',
    component: GuestDashboard,
    props: false,
  },
  {
    path: '/notification-test',
    name: 'NotificationTest',
    component: NotificationTest,
    props: false,
  },
  {
    path: '/notification',
    name: 'MyNotifications',
    component: MyNotifications,
    props: false,
  },
  {
    path: '/chats',
    name: 'ChatView',
    component: ChatView,
    props: false,
  },
  {
    path: '/notification-test',
    name: 'NotificationTest',
    component: NotificationTest,
    props: false,
  },

  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    props: false,
    children: [{
      path: '/admin',
      name: 'DashboardView',
      component: DashboardView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/listings',
      name: 'Listings',
      component: ListingsView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/listing-cost',
      name: 'ListingCost',
      component: ListingCost,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/users',
      name: 'Users',
      component: UsersView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/roles',
      name: 'Roles',
      component: RolesView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/permissions',
      name: 'Permissions',
      component: PermissionsView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/transactions',
      name: 'Transactions',
      component: TransactionsView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/categories',
      name: 'Categories',
      component: CategoriesView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/sub-categories',
      name: 'Subcategories',
      component: SubcategoriesView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/groups',
      name: 'Groups',
      component: GroupsView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/countries',
      name: 'Countries',
      component: CountriesView,
      meta: { requiresAuth: true },
      props: false,
    },
    {
      path: '/cities',
      name: 'Cities',
      component: CitiesView,
      meta: { requiresAuth: true },
      props: false,
    },
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
  }
]

const router = createRouter({
  mode: history,
  history: createWebHistory(),
  routes
})

export default router
