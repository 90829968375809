<template>
  <div>
    <GoogleMap
      :api-key="googleMapsApiKey"
      :style="mapStyle"
      :zoom="mapZoom"
      :center="center"
      @click="handleMapClick"
    >
      <!-- Loop over each city and create a marker for it -->
      <Marker
        v-for="city in formattedCities"
        :key="city"
        :options="{
          position: { lat: parseFloat(city.lat), lng: parseFloat(city.lng) },
        }"
        @click="openInfoWindow(city)"
      >
        <InfoWindow v-if="activeCity === city">
          <div id="content">{{ city.title }}, {{ city.country.name }}</div>
        </InfoWindow>
      </Marker>

      <!-- Marker for the user-selected location -->
      <Marker
        v-if="selectedLocation"
        :key="'selected-location'"
        :options="{
          position: selectedLocation,
        }"
        @click="openInfoWindowForSelected"
      >
        <InfoWindow v-show="selectedLocation">
          <div>{{ selectedTitle }}</div>
        </InfoWindow>
      </Marker>
    </GoogleMap>
  </div>
</template>
<script>
import { GoogleMap, Marker, InfoWindow } from "vue3-google-map";

export default {
  name: "GoogleMapComponent",
  components: {
    GoogleMap,
    Marker,
    InfoWindow,
  },
  props: {
    cities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      googleMapsApiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      center: { lat: 2.0392, lng: 45.3419 },
      infowindow: false,
      activeCity: null,
      selectedLocation: null,
      selectedTitle: "",
      customTitle: "",
    };
  },
  computed: {
    formattedCities() {
      if (Array.isArray(this.cities)) {
        return this.cities;
      } else if (this.cities && typeof this.cities === "object") {
        return [this.cities];
      }
      return [];
    },

    mapStyle() {
      return this.formattedCities.length > 1
        ? "width: 100%; height: 510px"
        : "width: 100%; height: 310px";
    },
    mapZoom() {
      return this.formattedCities.length > 1 ? 5 : 10;
    },
  },

  methods: {
    openInfoWindow(city) {
      this.activeCity = city;
    },

    handleMapClick(event) {
      console.log("theevent", event);
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      this.selectedLocation = { lat, lng };
      this.selectedTitle = "";
      this.getLocationName(lat, lng);
    },

    async getLocationName(lat, lng) {
      const service = new google.maps.places.PlacesService(
        document.createElement("div")
      );
      const request = {
        location: new google.maps.LatLng(lat, lng),
        radius: 50,
      };

      service.nearbySearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const place = results[0];
          console.log("Place:", place);
          this.selectedTitle =
            place.name || place.vicinity || "Unnamed Location";

          this.selectedLocation.title = this.selectedTitle;
          this.$store.dispatch(
            "map/updateSelectedLocation",
            this.selectedLocation
          );
        } else {
          console.error("Places API failed:", status);
          this.selectedTitle = "Unknown Location";
        }
      });
    },
  },
};
</script>
