const state = {
    unreadCount: 0,
};

const mutations = {
    SET_UNREAD_NOTIFICATION_COUNT(state, count) {
        state.unreadCount = count;
    },

    INCREMENT_UNREAD_NOTIFICATION_COUNT(state) {
        state.unreadCount++;
    },

    DECREMENT_UNREAD_NOTIFICATION_COUNT(state) {
        if (state.unreadCount > 0) {
            state.unreadCount--;
        }
    },
};

const actions = {
    setUnreadNotificationCount({ commit }, count) {
        commit('SET_UNREAD_NOTIFICATION_COUNT', count);
    },

    incrementUnreadNotificationCount({ commit }) {
        commit('INCREMENT_UNREAD_NOTIFICATION_COUNT');
    },

    decrementUnreadNotificationCount({ commit }) {
        commit('DECREMENT_UNREAD_NOTIFICATION_COUNT');
    },
};

const getters = {
    getUnreadNotificationCount: (state) => state.unreadCount,
};

export const notification = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}; 