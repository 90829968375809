<template>
  <div class="ma-2">
    <v-data-table
      :headers="headers"
      :items="listings"
      :sort-by="[{ key: '#', order: 'asc' }]"
      class=""
    >
      <template v-slot:top>
        <v-toolbar class="" flat>
          <v-toolbar-title
            class="text-h6 text-uppercase font-weight-bold text--darken-1 justify-center text-error"
            >{{ "My Listings (ads)" }}</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ props }">
              <v-btn
                class="mb-2"
                dark
                size="large"
                prepend-icon="mdi-plus-thick"
                variant="elevated"
                disabled
                v-bind="props"
                color="error"
              >
                New Listing
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span
                  class="text-caption text-uppercase font-weight-bold text--darken-1 justify-center text-blue"
                  >{{ formTitle }}</span
                >
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="isValid" @submit.prevent="save">
                    <v-row>
                      <v-col cols="12" md="6" sm="12">
                        <v-text-field
                          v-model="editedItem.title"
                          label="Title"
                          color="blue"
                          :rules="rules.name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-text-field
                          v-model="editedItem.price"
                          label="Price"
                          color="blue"
                          :rules="rules.slug"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-autocomplete
                          v-model="editedItem.transactionId"
                          label="Select Transaction"
                          :items="transactions"
                          item-text="title"
                          item-value="id"
                          return-object
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-autocomplete
                          v-model="editedItem.categoryId"
                          label="Select Category"
                          :items="categories"
                          item-title="name"
                          item-value="id"
                          return-object
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-autocomplete
                          v-model="editedItem.userId"
                          label="Select User"
                          :items="users"
                          item-title="fullName"
                          item-value="id"
                          return-object
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-autocomplete
                          v-model="editedItem.isFeatured"
                          label="Select Featured"
                          :items="fetured"
                          item-text="title"
                          item-value="id"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-autocomplete
                          v-model="editedItem.cityId"
                          label="Select Location(city)"
                          :items="cities"
                          item-title="title"
                          item-value="_id"
                          return-object
                        >
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="4" sm="12">
                        <v-autocomplete
                          v-model="editedItem.status"
                          label="Select Status"
                          :items="statuses"
                          item-text="title"
                          item-value="id"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-text-field
                          v-model="editedItem.contactInformation"
                          label="Email/Phone Number"
                          color="blue"
                          :rules="rules.slug"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="12">
                        <v-date-input
                          v-model="editedItem.expires_at"
                          label="Expiry Date"
                          color="blue"
                          prepend-icon=""
                          prepend-inner-icon="mdi-calendar"
                          format="MM-DD-YYYY"
                        ></v-date-input>
                      </v-col>
                      <v-col cols="12" md="4" sm="12">
                        <v-date-input
                          v-model="editedItem.approvedDate"
                          label="Approved Date"
                          prepend-icon=""
                          readonly
                          prepend-inner-icon="mdi-calendar"
                          color="blue"
                          format="MM-DD-YYYY"
                        ></v-date-input>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-textarea
                          v-model="editedItem.description"
                          label="Description"
                          color="blue"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-textarea
                          v-model="editedItem.rejectReason"
                          label="Rejection Reason(optional)"
                          color="blue"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions class="mr-7 mt-n7 mb-4">
                <v-spacer></v-spacer>
                <v-btn color="error" variant="outlined" @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue"
                  variant="outlined"
                  :disabled="!isValid"
                  @click="save"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="mx-10">
              <v-card-title class="text-subtitle-2 text-center text-blue">
                You sure you want to delete this listing?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" variant="outlined" @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue"
                  variant="outlined"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="ListDialog"
            transition="dialog-bottom-transition"
            fullscreen
          >
            <v-card>
              <v-toolbar>
                <v-btn
                  variant="outlined"
                  size="small"
                  color="blue"
                  icon="mdi-close"
                  @click="ListDialog = false"
                ></v-btn>

                <v-toolbar-title
                  class="text-blue text-uppercase font-weight-bold text--darken-1"
                  >{{ selectedItem.title }}</v-toolbar-title
                >

                <v-spacer></v-spacer>

                <v-toolbar-items>
                  <v-btn
                    text="Close"
                    variant="text"
                    color="blue"
                    @click="ListDialog = false"
                  ></v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <component
                  :is="getCategoryComponent(selectedItem.category.slug)"
                  :Listing="selectedItem"
                />
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.id`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.description`]="{ item }">
        {{ item.description ? item.description : "N/A" }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status == 1" class="text-success">
          {{ getStatusTitle(item.status) }}
        </div>
        <div v-else class="text-error">{{ getStatusTitle(item.status) }}</div>
      </template>
      <template v-slot:[`item.ApprovedDate`]="{ item }">
        {{ item.ApprovedDate ? formatDate(item.ApprovedDate) : "N/A" }}
      </template>
      <template v-slot:[`item.expires_at`]="{ item }">
        {{ item.expires_at ? formatDate(item.expires_at) : "N/A" }}
      </template>
      <template v-slot:[`item.transactionId.amount`]="{ item }">
        <div v-if="item.transactionId" class="text-success">
          {{ item.transactionId.amount }}
        </div>
        <div v-else class="text-error">
          {{ "Not Paid" }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <!-- menu -->

        <v-btn
          class="me-2"
          color="success"
          size="x-small"
          variant="outlined"
          icon="mdi-pencil"
          @click="setSelectedItem(item)"
        ></v-btn>

        <v-btn
          class="me-2"
          color="error"
          size="x-small"
          icon="mdi-delete"
          variant="outlined"
          @click="deleteItem(item)"
        ></v-btn>

        <!--  end menu -->
      </template>
      <template v-slot:no-data>
        <v-btn color="blue" variant="outlined" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import ListingService from "../services/listing.service.js";
import CategoriesService from "../services/category.services.js";
import CitiesService from "../services/cities.services.js";
import moment, { now } from "moment";
import TransactionsService from "../services/transaction.service.js";
import usersService from "../services/user.service.js";

import Classified from "../components/ViewList/ClassifiedList.vue";
import PropertyList from "../components/ViewList/PropertyList.vue";
import CommunityList from "../components/ViewList/CommunityList.vue";
import JobList from "../components/ViewList/JobList.vue";
import MotorList from "../components/ViewList/MotorList.vue";
import FurnitureGarden from "../components/ViewList/FurnitureGarden.vue";
import MobileTablet from "../components/ViewList/MobileTablet.vue";

export default {
  components: {
    Classified,
    PropertyList,
    CommunityList,
    JobList,
    MotorList,
    FurnitureGarden,
    MobileTablet,
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    ListDialog: false,
    selectedItem: {},
    isValid: false,
    rules: {
      name: [
        (value) => {
          if (value?.length >= 3) return true;
          return "You must enter a name.";
        },
      ],
      slug: [
        (value) => {
          if (value?.length >= 1) return true;
          return "You must enter a item.";
        },
      ],
      date: [
        (v) => !!v || "Expiry date is required",
        (v) => (v && moment(v, "MM-DD-YYYY", true).isValid()) || "Invalid date",
      ],
    },
    headers: [
      {
        title: "#",
        value: "id",
        align: "start",
        sortable: false,
        key: "id",
      },
      { title: "Title", value: "title", key: "title" },
      { title: "Price", value: "price", key: "price" },
      {
        title: "Transaction Amount",
        value: "transactionId.amount",
        key: "transactionId.amount",
      },
      { title: "Category", value: "category.name", key: "category.name" },

      { title: "Location(City)", value: "city.title", key: "city.title" },
      { title: "Status", value: "status", key: "status" },
      { title: "Expiry Date", value: "expires_at", key: "expires_at" },
      { title: "Approved Date", value: "ApprovedDate", key: "ApprovedDate" },
      { title: "Actions", key: "actions", align: "end", sortable: false },
    ],

    editedIndex: -1,
    listings: [],
    categories: [],
    transactions: [],
    users: [],
    cities: [],

    statuses: [
      { id: 1, title: "Approved" },
      { id: 2, title: "Pending" },
      { id: 3, title: "Rejected" },
    ],

    fetured: [
      { id: 1, title: "Featured" },
      { id: 0, title: "Not Featured" },
    ],
    newFiles: [],
    editedItem: {
      title: "",
      price: "",
      categoryId: "",
      transactionId: "",
      userId: "",
      cityId: "",
      isFeatured: "",
      view: "",
      tags: "",
      evc: "",
      currency: "",
      contactInformation: "",
      expires_at: new Date(),
      status: "",
      ApprovedDate: new Date(),
      rejectReason: "",
      description: "",
      condition: "",
      files: [],
    },

    defaultItem: {
      title: "",
      price: "",
      categoryId: "",
      transactionId: "",
      userId: "",
      cityId: "",
      isFeatured: "",
      view: "",
      tags: "",
      evc: "",
      currency: "",
      contactInformation: "",
      expires_at: new Date(),
      status: "",
      approvedDate: new Date(),
      rejectReason: "",
      description: "",
      condition: "",
      files: [],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Listing" : "Edit Listing";
    },

    loggedInUser() {
      return this.$store.state.auth.user;
    },

    formattedExpiryDate: {
      get() {
        return this.editedItem.expiryDate
          ? moment(this.editedItem.expiryDate).format("YYYY-MM-DD")
          : "";
      },
      set(value) {
        this.editedItem.expiryDate = moment(value, "YYYY-MM-DD").toDate();
      },
    },

    formattedApprovedDate: {
      get() {
        return this.editedItem.ApprovedDate
          ? moment(this.editedItem.ApprovedDate).format("YYYY-MM-DD")
          : "";
      },
      set(value) {
        this.editedItem.ApprovedDate = moment(value, "YYYY-MM-DD").toDate();
      },
    },

    formattedStartDate: {
      get() {
        return this.editedItem.startDate
          ? moment(this.editedItem.startDate).format("YYYY-MM-DD")
          : "";
      },
      set(value) {
        this.editedItem.startDate = moment(value, "YYYY-MM-DD").toDate();
      },
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getCategories();
    this.gettransactions();
    this.getUsers();
    this.getCities();
    if (this.loggedInUser) {
      this.getlistings();
    } else {
      this.handleLogout();
    }
  },

  methods: {
    async validate() {
      this.isValid = await this.$refs.form.validate();
    },

    getCategoryComponent(slug) {
      switch (slug) {
        case "property":
          return "PropertyList";
        case "motors":
          return "MotorList";
        case "community":
          return "CommunityList";
        case "jobs":
          return "JobList";
        case "classified":
          return "Classified";
        case "funiture&garden":
          return "FurnitureGarden";
        case "mobile&tables":
          return "MobileTablet";
        default:
          return null;
      }
    },

    getlistings() {
      return ListingService.getUserListings(this.loggedInUser._id).then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getCategories() {
      return CategoriesService.onlyCategories().then(
        (response) => {
          if (response.status == 200) {
            this.categories = response.data.data;
            this.categories = response.data.data.map((category) => ({
              ...category,
              title: category.name,
            }));
            this.loading = false;
          } else {
            this.categories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.categories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    gettransactions() {
      return TransactionsService.index().then(
        (response) => {
          if (response.status == 200) {
            // this.transactions = response.data.data;
            this.transactions = response.data.data.map((transaction) => ({
              ...transaction,
              title: transaction.transactionReference, // Set fullName to title
            }));
            this.loading = false;
          } else {
            this.transactions = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.transactions = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    addFiles() {
      console.log("Selected files:", this.newFiles);
      this.editedItem.files = [...this.newFiles];
      console.log("New files:", this.editedItem.files);
    },

    getUsers() {
      return usersService.index().then(
        (response) => {
          if (response.status == 200) {
            // this.users = response.data.data;
            this.users = response.data.data.map((user) => ({
              ...user,
              title: user.fullName, // Set fullName to title
            }));
            this.loading = false;
          } else {
            this.users = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.users = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getCities() {
      return CitiesService.index().then(
        (response) => {
          if (response.status == 200) {
            this.cities = response.data.data;
            this.loading = false;
          } else {
            this.cities = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.cities = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getStatusTitle(statusId) {
      const status = this.statuses.find((status) => status.id === statusId);
      return status ? status.title : "Unknown";
    },

    setSelectedItem(item) {
      console.log("ittt", item);
      this.selectedItem = { ...item };
      this.ListDialog = true;
    },

    editItem(item) {
      console.log("Selected item:", item);

      // Set the edited index
      this.editedIndex = this.listings.indexOf(item);

      // Clone the selected item
      this.editedItem = {
        id: item.id,
        title: item.title || "",
        price: item.price || 0,
        categoryId: item.category ? item.category : "", // Extract categoryId from nested category object
        transactionId: item.transactionId || "",
        userId: item.user ? item.user : "", // Extract userId from nested user object (if available)
        cityId: item.city ? item.city : "", // Extract cityId from nested city object
        isFeatured: item.isFeatured || "",
        views: item.views || "",
        tags: item.tags || "",
        evc: item.evc || "",
        currency: item.currency || "",
        contactInformation: item.contactInformation || "",
        expires_at: item.expires_at ? new Date(item.expires_at) : new Date(),
        status: item.status || "",
        approvedDate: item.approvedDate
          ? this.formatDate(item.ApprovedDate)
          : this.formatDate(moment()),
        rejectReason: item.rejectReason || "",
        description: item.description || "",
        condition: item.condition || "",
        files: item.files || [], // If files array is present, assign it, otherwise empty array
      };

      // Ensure ApprovedDate is formatted correctly
      if (!this.editedItem.ApprovedDate) {
        this.editedItem.ApprovedDate = this.formatDate(moment());
      }

      // Open the dialog for editing
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.listings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ListingService.delete(this.editedItem).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.listings.splice(this.editedIndex, 1);
            this.closeDelete();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },

    formatDate(date) {
      return moment(date).format("MM-DD-YYYY");
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.editedItem.name = this.editedItem.title;
      console.log("we are here", this.editedItem);
      if (this.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.listings[this.editedIndex], this.editedItem);
          ListingService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.close();
                this.loading = false;
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        } else {
          ListingService.store(this.editedItem).then(
            (response) => {
              if (response.status == 201) {
                this.listings.push(this.editedItem);
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      }
    },
  },
};
</script>