<template>
  <div class="">
    <v-app>
      <!-- app bar -->
      <v-app-bar
        :elevation="0"
        v-if="!this.$vuetify.display.mobile"
        color="grey-lighten-4 px-11"
        variant="outlined"
      >
        <!-- Clickable Logo -->
        <v-img
          :src="require('@/assets/suuq.logo.svg')"
          alt="SUUQ Logo"
          class="ml-8"
          @click="logoRoute"
          cover
          max-width="100"
        />

        <v-spacer></v-spacer>
        <div v-for="item in ecosystem" :key="item" class="mx-5 text-grey">
          <v-btn @click="routeTo(item.route)" density="compact" stacked>
            <v-badge
              v-if="item.notify && loggedInUser && unreadNotificationCount"
              color="error"
              :content="unreadNotificationCount"
            >
              <v-icon size="x-small">{{ item.icon }}</v-icon>
            </v-badge>

            <v-badge
              v-else-if="item.chaty && loggedInUser && unreadMessageCount"
              color="error"
              :content="unreadMessageCount"
            >
              <v-icon size="x-small">{{ item.icon }}</v-icon>
            </v-badge>
            <div v-else>
              <v-icon size="x-small">{{ item.icon }}</v-icon>
            </div>
            <span class="text-caption">{{ item.text }}</span>
          </v-btn>
        </div>
        <!-- Login or Signup button for larger screens -->
        <div v-if="$vuetify.display.mdAndUp">
          <div v-if="!loggedInUser">
            <v-btn
              variant="text"
              class="text-caption mr-10"
              @click="loginOrSignup"
            >
              Login or Signup
            </v-btn>

            <!-- login dialog -->
            <v-dialog v-model="showLoginDialog" width="600">
              <v-card max-width="900"> <auth-component /> </v-card>
            </v-dialog>
          </div>
          <div v-else>
            <v-menu open-on-hover>
              <template v-slot:activator="{ props }">
                <v-avatar
                  v-bind="props"
                  size="small"
                  class="mr-8"
                  color="error"
                >
                </v-avatar>
              </template>
              <v-list>
                <v-list-item @click="handleLogout">
                  <v-list-item-title>
                    <div class="pa-1">
                      <v-icon
                        size="small"
                        color="blue"
                        icon="mdi-logout-variant"
                      >
                      </v-icon>
                      Logout
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <v-btn
          @click="placeAd"
          color="error"
          class="error text-caption mr-16"
          variant="flat"
        >
          Place Your Ad</v-btn
        >
      </v-app-bar>

      <!--small screen search -->
      <div class="mx-1 mt-1" v-if="this.$vuetify.display.mobile">
        <v-text-field
          v-model="searchQuery"
          variant="outlined"
          rounded="xl"
          placeholder="search for anything"
        >
          <template v-slot:append-inner>
            <v-icon color="error">mdi-magnify</v-icon>
          </template>
          <template v-slot:prepend-inner>
            <v-icon color="error">mdi-home</v-icon>
          </template>
        </v-text-field>
      </div>
      <!-- end of search -->

      <!-- categories -->
      <div>
        <v-app-bar
          :elevation="0"
          color="grey-lighten-4 px-11"
          class="mt-1 mb-1"
          density="compact"
          v-if="!this.$vuetify.display.mobile"
        >
          <div
            v-for="(category, index) in categories"
            :key="index"
            class="text-center ml-12 mx-10"
          >
            <v-btn class="text-caption font-weight-black" variant="text">
              {{ category.title }}
              <v-menu open-on-hover activator="parent">
                <v-card min-width="250">
                  <v-list>
                    <v-list-item
                      v-for="(subCategory, index) in category.subCategories"
                      :key="index"
                      link
                      @click="selectAttributes(subCategory)"
                      :to="{
                        name: 'list',
                        query: {
                          category: category.title,
                          subcategory: subCategory.title,
                        },
                      }"
                    >
                      <v-list-item-title class="text-caption">{{
                        subCategory.title
                      }}</v-list-item-title>
                      <template v-slot:append>
                        <!-- <v-icon
                            v-if="subCategory"
                            icon="mdi-menu-right"
                            size="x-small"
                          ></v-icon> -->
                      </template>

                      <v-menu
                        :open-on-focus="false"
                        activator="parent"
                        open-on-hover
                        submenu
                      >
                        <v-list elevation="0" class="mt-n2">
                          <v-list-item
                            v-for="(group, index) in subCategory.groups"
                            :key="index"
                            link
                            @click="selectAttributes(group)"
                            :to="{
                              name: 'list',
                              query: {
                                category: category.title,
                                subcategory: subCategory.title,
                                group: group.name,
                              },
                            }"
                          >
                            <v-list-item-title class="text-caption">{{
                              group.name
                            }}</v-list-item-title>
                            <!-- <template v-slot:append>
                            <v-icon
                              icon="mdi-menu-right"
                              size="x-small"
                            ></v-icon>
                          </template> -->
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-btn>
          </div>
        </v-app-bar>
      </div>
      <!-- end of categories -->

      <!-- main content here -->
      <v-main class="mx-16 ma-1 mt-2">
        <v-row class="mx-5">
          <v-col cols="12" v-if="$vuetify.display.mdAndUp">
            <!-- <router-view></router-view> -->
            <v-card class="ma-auto">
              <v-img cover src="../assets/hero_image.jpeg">
                <div class="text-center">
                  <div class="text-white text-h6 font-weight-black mt-8">
                    <p class="">
                      The best place to buy your house, sell your car or find a
                      job in Somalia
                    </p>
                  </div>
                  <div class="mt-4 mx-5 text-white">
                    <v-row>
                      <v-col cols="12">
                        <v-card class="ma-5 mx-auto custom-card" outlined>
                          <v-col
                            cols="12"
                            class="text-white font-weight-bold text-left ml-5 mt-4 mb-n5"
                          >
                            <v-row class="mr-10">
                              <v-col cols="12">
                                <v-card
                                  elevation="0"
                                  class="rounded-lg"
                                  color="grey lighten-4"
                                >
                                  <v-toolbar class="rounded-lg py-4">
                                    <v-row class="mt-3 ml-1" align="center">
                                      <v-col cols="12" sm="6" md="2">
                                        <v-select
                                          v-model="filter.categoryId"
                                          :items="categories"
                                          label="Select category"
                                          variant="solo-inverted"
                                          dense
                                          item-value="id"
                                          item-text="title"
                                          class="rounded-lg elevation-0"
                                        ></v-select>
                                      </v-col>

                                      <v-col cols="12" sm="6" md="2">
                                        <v-autocomplete
                                          v-model="filter.location"
                                          label="City"
                                          :items="locations"
                                          dense
                                          menu-icon=""
                                          variant="solo-inverted"
                                          class="rounded-lg"
                                          append-icon-color="red"
                                          item-value="id"
                                        >
                                          >
                                          <template v-slot:append-inner>
                                            <v-icon color="error"
                                              >mdi-map-marker</v-icon
                                            >
                                          </template>
                                        </v-autocomplete>
                                      </v-col>

                                      <v-col cols="12" sm="6" md="2">
                                        <v-autocomplete
                                          v-model="filter.propertyType"
                                          label="Select group"
                                          :items="groups"
                                          dense
                                          variant="solo-inverted"
                                          class="rounded-lg"
                                          item-text="title"
                                          item-value="_id"
                                        ></v-autocomplete>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="2">
                                        <v-select
                                          v-model="filter.priceRange"
                                          label="Price Range"
                                          :items="priceRanges"
                                          dense
                                          variant="solo-inverted"
                                          class="rounded-lg"
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="2">
                                        <v-select
                                          v-model="filter.bedrooms"
                                          label="Bedrooms"
                                          :items="[
                                            1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
                                          ]"
                                          dense
                                          variant="solo-inverted"
                                          class="rounded-lg"
                                          v-if="
                                            selectedSearchCategory &&
                                            selectedSearchCategory.slug ==
                                              'property'
                                          "
                                        ></v-select>
                                        <v-select
                                          v-model="filter.usage"
                                          label="Usage"
                                          :items="[
                                            'Light',
                                            'Moderate',
                                            'Heavy',
                                          ]"
                                          variant="solo-inverted"
                                          v-if="
                                            selectedSearchCategory &&
                                            (!selectedSearchCategory ||
                                              selectedSearchCategory.slug !==
                                                'property')
                                          "
                                        ></v-select>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="2">
                                        <v-btn
                                          color="error"
                                          class="mt-n5 ml-10"
                                          size="x-large"
                                          variant="flat"
                                          :disabled="!isSearchEnabled"
                                          @click="filterListings"
                                        >
                                          <span class="text-caption"
                                            >Search</span
                                          >
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-toolbar>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-row class="mt-4 opacity-100">
                            <v-col cols="10">
                              <v-text-field
                                v-model="searchQuery"
                                color="white"
                                placeholder="Search for anything"
                                append-inner-icon="mdi-magnify"
                                variant="solo"
                                class="ml-8 text-white"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="auto">
                              <v-btn class="error" color="error" size="x-large"
                                >Search</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-img>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="$vuetify.display.mdAndUp">
            <v-card color="blue-lighten-5 mb-n3">
              <v-row class="ml-n15">
                <v-col cols="4">
                  <v-img
                    height="150"
                    class="mt-n3 ml-n15"
                    src="../assets/new_project_banner_images.png"
                  ></v-img>
                </v-col>
                <v-col cols="4">
                  <div class="ml-n15">
                    <div class="mt-10 ml-n15 font-weight-bold">
                      Introducing New projects
                    </div>
                    <div class="ml-n15">
                      Get access to te latest property development
                    </div>
                  </div>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn
                    class="mt-10 mr-5"
                    elevation="0"
                    size="x-large"
                    append-icon="mdi-arrow-right"
                    >Explore Now</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="$vuetify.display.mdAndUp">
            <v-card class="pa-2" elevation="0" :loading="loading">
              <!-- categories -->
              <div class="font-weight-bold">Popular Categories</div>
              <v-row class="text-left">
                <v-col
                  cols="12"
                  md="3"
                  v-for="(category, index) in fetureCategories"
                  :key="index"
                >
                  <v-card class="mx-auto mt-3 my-4" elevation="1" outlined>
                    <v-card-title>
                      <v-row>
                        <v-col cols="2" md="2">
                          <v-img
                            :src="require(`@/assets/${category.icon}.svg`)"
                            alt="icon"
                            max-width="15"
                            class="mr-3"
                          ></v-img>
                        </v-col>
                        <v-col cols="auto" class="mt-n3 ml-n7">
                          <span class="font-weight-bold text-caption">{{
                            category.title
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text>
                      <v-list dense>
                        <v-list-item
                          v-for="(sub, i) in category.subcategories"
                          :key="i"
                          class="mt-n5 ml-n3 hover-effect"
                          @click="findCategory(category)"
                        >
                          <v-list-item-content>
                            <v-list-item-title class="text-caption">{{
                              sub.title
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          class="mt-n5 ml-n3"
                          @click="findCategory(category)"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-bold text-caption text-error"
                              >{{ category.allInText }}
                              <span
                                ><v-icon icon="mdi-arrow-right"></v-icon
                              ></span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- popular mobile categories -->
          <div class="mx-auto" v-if="$vuetify.display.mobile">
            <v-row class="mx-n16">
              <v-col
                cols="6"
                v-for="(category, index) in categories"
                :key="index"
                class=""
              >
                <v-card
                  height="110"
                  width="145"
                  @click="handleCategorySelection(category)"
                  elevation="3"
                  class="my-auto"
                >
                  <v-row class="text-center">
                    <v-col cols="12" class="d-flex mt-10 justify-center">
                      <v-img
                        :src="require(`@/assets/${category.slug}.svg`)"
                        alt="icon"
                        max-width="25"
                        class="mr-2"
                      ></v-img>
                    </v-col>
                    <v-col cols="12" class="text-center mt-n2 mb-4">
                      <div class="text-caption font-weight-black">
                        {{ category.title }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <!-- <v-col :cols="categories.length > 0 ? 6 : 12" class="pa-5">
                <v-card
                  height="110"
                  elevation="3"
                  class="my-auto"
                >
                  <v-row class="text-center">
                    <v-col cols="12" class="d-flex mt-10 justify-center">
                      <v-icon
                        color="error"
                        icon="mdi-arrow-right"
                        size="large"
                      ></v-icon>
                    </v-col>
                    <v-col cols="12" class="text-center mt-n2 mb-4">
                      <div class="text-caption font-weight-black">
                        All Categories
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col> -->
            </v-row>
          </div>

          <v-col cols="12" v-if="$vuetify.display.mdAndUp">
            <v-card color="blue-lighten-5 mt-n2 mb-3">
              <v-row class="ml-n15">
                <v-col cols="4">
                  <v-img
                    height="100"
                    class="ml-n15"
                    src="../assets/verified-badge-new-blue.svg"
                  ></v-img>
                </v-col>
                <v-col cols="4">
                  <div class="ml-n15">
                    <div class="mt-10 ml-n15">
                      Got verified budge yet?
                      <span class="text-caption ml-3">Get more visibility</span>
                      <v-divider
                        class="ms-2 border-opacity-50"
                        inset
                        vertical
                      ></v-divider>
                      <span class="text-caption">Enhance your credibility</span>
                    </div>
                  </div>
                </v-col>
                <v-col cols="4" class="text-right">
                  <v-btn class="mt-8 mr-5" elevation="0">Get Started</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card
              class="pa-1"
              elevation="0"
              :class="this.$vuetify.display.mobile ? 'mx-n15' : ''"
            >
              <!-- products -->
              <v-row
                v-for="(listings, categorySlug) in groupedListings"
                :key="categorySlug"
                class="category-scroll-container"
              >
                <v-col cols="12">
                  <div class="font-weight-bold mb-2">
                    {{
                      "Popular Products in" +
                      " " +
                      getCategoryName(categorySlug)
                    }}
                  </div>
                </v-col>

                <!-- Horizontal Scrollable Container -->
                <v-col cols="12">
                  <div class="category-scroll-wrapper">
                    <v-row
                      class="category-scroll"
                      no-gutters
                      align="start"
                      justify="start"
                    >
                      <v-col
                        v-for="listing in listings.slice(0, 5)"
                        :key="listing.id"
                        cols="auto"
                      >
                        <v-card
                          elevation="0"
                          class="ml-1"
                          :width="this.$vuetify.display.mobile ? '380' : '244'"
                          @click="selectItem(listing)"
                          :loading="loading"
                        >
                          <v-img
                            class="bg-grey-lighten-2"
                            height="160"
                            :src="`data:${listing.files[0]?.mime_type};base64,${listing.files[0]?.base64_data}`"
                            cover
                          ></v-img>
                          <v-card-title
                            elevation="0"
                            :class="
                              this.$vuetify.display.mobile
                                ? 'text-left ml-n3'
                                : 'text-left  ml-n3'
                            "
                          >
                            <div
                              class="text-error text-button font-weight-bold"
                            >
                              {{ "USD" + " " + listing.price }}
                            </div>
                            <div
                              class="text-black mt-n2 font-weight-bold text-caption"
                            >
                              {{ listing.title }}
                            </div>
                            <div class="text-grey text-caption text-truncate">
                              {{ listing.description }}
                            </div>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-main>
      <!-- end of main content-->

      <div v-if="!this.$vuetify.display.mobile">
        <FooterComponent></FooterComponent>
      </div>
    </v-app>

    <!-- small screen bottom nav -->
    <v-footer v-if="this.$vuetify.display.mobile" class="hidden-md-and-up" app>
      <!-- bottom nav -->
      <v-bottom-navigation class="mb-n5 pa-2 text-grey">
        <div class="mb-4 pa-1">
          <v-list class="d-flex mx-auto justify-space-between">
            <v-list-item
              v-for="(item, index) in bottomNav"
              :key="index"
              :value="item.name"
              :to="item.route"
              :class="['mx-1', 'mt-n4', 'pb-2', 'text-grey']"
              @click="toggle(item)"
            >
              <template v-slot:title>
                <div></div>
                <div class="text-center">
                  <v-icon size="x-small">{{ item.icon }}</v-icon>
                </div>
                <div class="text-caption pb-1">{{ item.name }}</div>
              </template>
            </v-list-item>
          </v-list>
        </div>
      </v-bottom-navigation>
      <!-- menu dialog -->
      <v-dialog v-model="menuActive" width="auto" class="ma-2">
        <v-card class="ml-n7 pa-3" width="350">
          <v-row no-gutters>
            <v-col cols="12" v-if="!loggedInUser">
              <v-btn
                variant="tonal"
                color="error"
                class="text-caption mr-10"
                @click="loginOrSignup"
                block
              >
                Login or Signup
              </v-btn>

              <v-dialog v-model="showLoginDialog" width="380">
                <v-card
                  :class="this.$vuetify.display.mobile ? 'ml-n3' : ''"
                  :width="this.$vuetify.display.mobile ? '340' : '900'"
                >
                  <auth-component />
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" v-else>
              <div class="text-center">
                <v-avatar color="error" size="70"></v-avatar>
                <div class="my-4 font-weight-black text-caption">
                  {{ loggedInUser.fullName }}
                  <div>{{ loggedInUser.email }}</div>
                </div>
              </div>
              <v-card-actions color="blue">
                <v-btn
                  color="blue"
                  @click="handleLogout"
                  prepend-icon="mdi-logout-variant"
                  variant="outlined"
                  block
                >
                  Logout
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>

          <div></div>
        </v-card>
      </v-dialog>
    </v-footer>
    <!-- end small screen bottom nav -->
  </div>
</template>
<script>
import FooterComponent from "@/components/footer/FooterComponent.vue";
import CategoryService from "@/services/category.services";
import ListingService from "../services/listing.service.js";
import AuthComponent from "./auth/authComponent.vue";
import CityService from "../services/cities.services";
import CountryService from "../services/country.services.js";
import GroupsService from "../services/group.services.js";
import NotificationService from "@/services/notification.service.js";
import ChatService from "@/services/chat.service.js";

export default {
  name: "HomeComponent",
  components: {
    FooterComponent,
    AuthComponent,
  },

  data: () => ({
    value: 1,
    listedItems: [],
    locations: [],
    myNotifications: [],
    showLoginDialog: false,
    loading: true,
    ecosystem: [
      {
        text: "Notification",
        route: "/notification",
        notify: true,
        icon: "mdi-bell-outline",
      },
      {
        text: "My Searches",
        route: "/my-search",
        icon: "mdi-feature-search-outline",
      },
      {
        text: "Chats",
        route: "/chats",
        icon: "mdi-message-text",
        chaty: true,
      },
      {
        text: "My Ads",
        route: "/my-ads",
        icon: "mdi-view-list-outline",
      },
    ],

    filter: {
      categoryId: "",
      location: "",
      purpose: "",
      bedrooms: 0,
      priceRange: 0,
      propertyType: "",
      usage: "",
    },

    defaultFilter: {
      categoryId: "",
      location: "",
      purpose: "",
      bedrooms: 0,
      priceRange: 0,
      propertyType: "",
      usage: "",
    },

    bottomNav: [
      {
        name: "Home",
        route: "/",
        icon: "mdi-home",
      },
      {
        name: "notification",
        route: "",
        slug: "notifyMe",
        icon: "mdi-bell",
      },
      {
        name: "My Searches",
        route: "",
        slug: "mySearches",
        icon: "mdi-feature-search-outline",
        menu: "search",
      },
      {
        name: "Post an Ad",
        route: "",
        slug: "placeAd",
        icon: "mdi-plus-circle-outline",
      },
      {
        name: "Chats",
        route: "",
        slug: "chatMe",
        icon: "mdi-message-text",
      },
      {
        name: "My Ads",
        route: "",
        slug: "myAds",
        icon: "mdi-view-list-outline",
      },
      {
        name: "menu",
        route: "",
        icon: "mdi-menu",
        menu: "menu",
      },
    ],

    fetureCategories: [
      {
        icon: "motors",
        title: "Motors",
        slug: "motors",
        subcategories: [
          { title: "Cars" },
          { title: "Motorcycles" },
          { title: "Auto Accessories & Parts" },
          { title: "Heavy Vehicles" },
        ],
        allInText: "All in Motors",
      },
      {
        icon: "property-for-rent",
        title: "Property for Rent",
        slug: "property",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "Rooms For Rent" },
          { title: "Monthly Short Term" },
        ],
        allInText: "All in Property for Rent",
      },
      {
        icon: "property-for-sale",
        title: "Property for Sale",
        slug: "property",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "New Projects" },
          { title: "Off-Plan" },
        ],
        allInText: "All in Property for Sale",
      },
      {
        icon: "classified",
        title: "Classifieds",
        slug: "classified",
        subcategories: [
          { title: "Electronics" },
          { title: "Computers & Networking" },
          { title: "Clothing & Accessories" },
          { title: "Jewelry & Watches" },
        ],
        allInText: "All in Classifieds",
      },
      {
        icon: "jobs",
        title: "Jobs",
        slug: "jobs",
        subcategories: [
          { title: "Accounting / Finance" },
          { title: "Engineering" },
          { title: "Sales / Business Development" },
          { title: "Secretarial / Front Office" },
        ],
        allInText: "All in Jobs",
      },
      {
        icon: "community",
        title: "Community",
        slug: "community",
        subcategories: [
          { title: "Freelancers" },
          { title: "Home Maintenance" },
          { title: "Other Services" },
          { title: "Tutors & Classes" },
        ],
        allInText: "All in Community",
      },
      {
        icon: "business-industrial",
        title: "Business & Industrial",
        slug: "community",
        subcategories: [
          { title: "Businesses for Sale" },
          { title: "Construction" },
          { title: "Food & Beverage" },
          { title: "Industrial Supplies" },
        ],
        allInText: "All in Business & Industrial",
      },
      {
        icon: "home-appliances",
        title: "Home Appliances",
        slug: "funiture&garden",
        subcategories: [
          { title: "Large Appliances / White Goods" },
          { title: "Small Kitchen Appliances" },
          { title: "Outdoor Appliances" },
          { title: "Small Bathroom Appliances" },
        ],
        allInText: "All in Home Appliances",
      },
      {
        icon: "furniture-home-garden",
        title: "Furniture, Home & Garden",
        slug: "funiture&garden",
        subcategories: [
          { title: "Furniture" },
          { title: "Home Accessories" },
          { title: "Garden & Outdoor" },
          { title: "Lighting & Fans" },
        ],
        allInText: "All in Furniture, Home & Garden",
      },
      {
        icon: "mobile-phones-pdas",
        title: "Mobile Phones & Tablets",
        slug: "mobile&tables",
        subcategories: [
          { title: "Mobile Phones" },
          { title: "Mobile Phone & Tablet Accessories" },
          { title: "Tablets" },
          { title: "Other Mobile Phones & Tablets" },
        ],
        allInText: "All in Mobile Phones & Tablets",
      },
    ],

    priceRanges: [
      { title: "Under $50", value: "0_50" },
      { title: "$50 - $100", value: "50_100" },
      { title: "$100 - $200", value: "100_200" },
      { title: "$200 - $500", value: "200_500" },
      { title: "Above $500", value: "500_10000000" },
    ],

    menuActive: false,
    searchActive: false,
    categories: [],
    listings: [],
    groups: [],
    searchQuery: "",
  }),
  computed: {
    isSearchEnabled() {
      return (
        this.filter.categoryId ||
        this.filter.location ||
        this.filter.propertyType ||
        this.filter.priceRange ||
        this.filter.bedrooms ||
        this.filter.usage
      );
    },

    loggedInUser() {
      return this.$store.state.auth.user;
    },

    user() {
      return this.loggedInUser || {};
    },

    groupedListings() {
      const filteredListings = this.listings.filter((listing) => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (
          listing.title.toLowerCase().includes(searchQueryLower) ||
          listing.description.toLowerCase().includes(searchQueryLower)
        );
      });

      return filteredListings.reduce((grouped, listing) => {
        const categorySlug = listing.category.slug;
        if (!grouped[categorySlug]) {
          grouped[categorySlug] = [];
        }
        grouped[categorySlug].push(listing);
        return grouped;
      }, {});
    },

    unreadNotificationCount() {
      return this.$store.state.notification.unreadCount;
    },

    unreadMessageCount() {
      return this.$store.state.chat.unreadMessageCount;
    },

    filteredListings() {
      return this.listings.filter((listing) => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (
          listing.title.toLowerCase().includes(searchQueryLower) ||
          listing.description.toLowerCase().includes(searchQueryLower)
        );
      });
    },

    selectedSearchCategory() {
      return (
        this.categories.find((cat) => cat.id === this.filter.categoryId) || {}
      );
    },
  },

  created() {
    this.getCategories();
    this.getlistings();
    this.getDefaultCountry();
    this.getGroups();
    if (this.loggedInUser) {
      this.getNotifications();
      this.getUserChats();
    }
    console.log("unreadNotificationCount", this.unreadNotificationCount);
  },

  methods: {
    getCategoryName(slug) {
      if (slug && this.categories) {
        const category = this.categories.find((cat) => cat.slug === slug);
        return category ? category.title : "Unknown Category";
      }
    },

    findCategory(selectedCategory) {
      const category = this.categories.find(
        (cat) => cat.slug === selectedCategory.slug
      );

      if (category) {
        // console.log("Category found:", category);
        this.$store.dispatch("filter/setSelectedAttributes", category);
        this.$router.push({
          name: "list",
          query: {
            category: category.title,
          },
        });
      } else {
        console.log("No category found");
      }
    },

    getNotifications() {
      return NotificationService.userNotifications(this.loggedInUser._id).then(
        (response) => {
          if (response.status == 200) {
            this.myNotifications = response.data.data;
            console.log("this.myNotifications", this.myNotifications);
            this.$store.dispatch(
              "notification/setUnreadNotificationCount",
              this.myNotifications.length
            );
            this.loading = false;
          } else {
            this.myNotifications = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.myNotifications = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getUserChats() {
      ChatService.getUserChats(this.loggedInUser._id).then((response) => {
        if (response.status === 200) {
          this.userChats = response.data.data || [];
          this.updateUnreadMessagesCount();
        } else {
          this.userChats = [];
        }
      });
    },

    updateUnreadMessagesCount() {
      const totalUnread = this.userChats.reduce(
        (sum, chat) => sum + (chat.unread_messages_count || 0),
        0
      );
      this.$store.dispatch("chat/setUnreadMessageCount", totalUnread);
    },

    selectItem(item) {
      this.$store.dispatch("category/setSelectedItem", item);
      this.$router.push("/product");
    },

    filterListings() {
      this.$store.dispatch("filter/setFilterAttributes", this.filter);
      console.log("his.filter", this.filter);
      this.$router.push("/list");
    },

    selectAttributes(item) {
      this.$store.dispatch("filter/setSelectedAttributes", item);
    },

    handleCategorySelection(category) {
      if (!category) {
        this.$router.push({
          name: "list",
        });
      } else {
        this.$store.dispatch("filter/setSelectedAttributes", category);
        this.$router.push({
          name: "list",
          query: {
            category: category.title,
          },
        });
      }
    },

    handleLogout() {
      this.menuActive = false;
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    getDefaultCountry() {
      return CountryService.defaultCountry().then(
        (response) => {
          if (response.status == 200) {
            this.country = response.data.data;
            this.getcities(this.country.id);
            this.loading = false;
          } else {
            this.country = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.country = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getGroups() {
      return GroupsService.index().then(
        (response) => {
          if (response.status == 200) {
            // this.groups = response.data.data;
            this.groups = response.data.data;
            this.loading = false;
          } else {
            this.groups = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.getGroups = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    // get cities
    getcities(countryId) {
      return CityService.getCountryCities(countryId).then(
        (response) => {
          if (response.status == 200) {
            this.locations = response.data.data.slice(0, 6);
            this.loading = false;
          } else {
            this.locations = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.locations = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getCategories() {
      return CategoryService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.categories = response.data.data;
            this.loading = false;
          } else {
            this.categories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.loading = false;
          this.categories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getlistings() {
      return ListingService.getApprovedOpenListing().then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            // console.log("the list", this.listings);
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    async getlistingFile(gridfs_id) {
      return ListingService.getListingFile(gridfs_id).then(
        (response) => {
          if (response.status == 200) {
            const mimeType = response.headers["content-type"];
            const base64Image = this.convertToBase64(response.data, mimeType);
            return base64Image;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.console.error);
            return null;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          return null;
        }
      );
    },

    convertToBase64(binaryData, mimeType) {
      // Ensure binary data is a Uint8Array
      const base64String = btoa(
        new Uint8Array(binaryData).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return `data:${mimeType};base64,${base64String}`;
    },

    loginOrSignup() {
      if (!this.loggedInUser) {
        this.showLoginDialog = true;
      }
    },

    routeTo(route) {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || route);
    },

    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },

    placeAd() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/place-ad");
    },

    notifyMe() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/notification");
    },

    myChats() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/chats");
    },

    myAds() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/my-ads");
    },

    mySearches() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/my-searches");
    },

    toggle(item) {
      if (item.slug && item.slug === "placeAd") {
        this.placeAd();
        return;
      }

      if (item.slug && item.slug === "notifyMe") {
        this.notifyMe();
        return;
      }

      if (item.slug && item.slug === "mySearches") {
        this.mySearches();
        return;
      }

      if (item.slug && item.slug === "chatMe") {
        this.myChats();
        return;
      }

      if (item.slug && item.slug === "myAds") {
        this.myAds();
        return;
      }

      if ((!item.route && item.menu === "menu") || "search") {
        item.menu === "menu"
          ? (this.menuActive = !this.menuActive)
          : (this.searchActive = !this.searchActive);
      }
    },

    isLoggedIn() {
      return !!localStorage.getItem("token");
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    base64ToFile(base64String, fileType, fileName) {
      const base64Data = base64String.replace(/^data:.+;base64,/, "");
      const byteCharacters = atob(base64Data);
      const byteArray = [...byteCharacters].map((char) => char.charCodeAt(0));
      const blob = new Blob([new Uint8Array(byteArray)], { type: fileType });
      const file = new File([blob], fileName, { type: fileType });
      console.log(file);
      return file;
    },
  },
};
</script>
<style scoped>
.v-input__icon--append .v-icon {
  color: purple;
}

.custom-card {
  background-color: rgba(0, 0, 0, 0.6);
}

.hover-effect {
  background-color: transparent !important;
  transition: color 0.3s ease;
}

.hover-effect:hover {
  background-color: transparent !important;
  color: blue !important;
}

.v-list-item-title {
  color: inherit;
}

.category-scroll-wrapper {
  overflow-x: auto;
  padding-bottom: 16px; /* To prevent content from being hidden by scrollbar */
}

.category-scroll {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping, keep all items in one row */
}

.v-col[cols="auto"] {
  margin-right: 16px; /* Optional: Adjust space between items */
}
</style>
