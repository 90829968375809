<template>
  <div>
    <!-- App Bar -->
    <v-app-bar class="text-center" :elevation="1">
      <!-- Clickable Logo -->
      <v-img
        :src="require('@/assets/suuq.logo.svg')"
        alt="SUUQ Logo"
        @click="logoRoute"
      />
    </v-app-bar>

    <!-- Main Content -->
    <div class="main-content">
      <v-card elevation="0" width="600" class="mx-auto mt-5">
        <v-card-title class="text-center mb-5">
          Hello, what are you listing today?
          <div class="text-grey text-caption">
            Select the area that best suits your ad
          </div>
        </v-card-title>

        <v-card-text>
          <!-- Categories -->
          <v-row v-if="!loading">
            <!-- Loop through the categories and display cards -->
            <v-col
              cols="6"
              md="4"
              v-for="(category, index) in categories"
              :key="index"
              :class="{
                'd-flex justify-center':
                  $vuetify.display.mobile,
              }"
            >
              <v-card
                :width="this.$vuetify.display.mobile ? 300 : 160"
                height="160"
                @click="selectCategory(category)"
                elevation="3"
                class="my-auto"
              >
                <v-row class="text-center">
                  <v-col cols="12" class="d-flex mt-10 justify-center">
                    <v-img
                      :src="require(`@/assets/${category.slug}.svg`)"
                      alt="icon"
                      max-width="45"
                      class="mr-3"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <div class="text-caption font-weight-black">
                      {{ category.name }}
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <!-- Loading Spinner -->
          <v-row v-if="loading">
            <v-col cols="12" class="text-center">
              <v-progress-circular
                :size="70"
                :width="7"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>

          <!-- Continue from Drafts Section -->
          <div
            v-if="userDrafts"
            class="text-center text-error text-caption mt-5 mb-5"
          >
            OR continue posting from your drafts
          </div>
          <div v-if="userDrafts">
            <v-card
              height="60"
              v-for="(draft, index) in userDrafts"
              :key="draft.id"
              class="mt-2"
            >
              <v-card-title class="d-flex">
                <!-- <div> -->
                <v-avatar
                  color="error"
                  class="mt-3 text-caption font-weight-black"
                  size="x-small"
                >
                  {{ index + 1 }}
                </v-avatar>
                <div class="mt-4 text-caption font-weight-black ml-4">
                  {{ draft.title }}
                </div>
                <!-- </div> -->
                <v-spacer></v-spacer>
                <div>
                  <v-avatar
                    @click="selectDraftAndNavigate(draft)"
                    class="mt-3"
                    color="grey-lighten-4"
                    size="small"
                  >
                    <v-icon
                      class=""
                      icon="mdi-chevron-right"
                      color="error"
                      size="x-small"
                    ></v-icon>
                  </v-avatar>

                  <v-avatar
                    @click="deleteDraft(draft)"
                    class="mt-3 ml-2"
                    color="grey-lighten-4"
                    size="small"
                  >
                    <v-icon
                      class=""
                      icon="mdi-delete"
                      color="error"
                      size="x-small"
                    ></v-icon>
                  </v-avatar>
                </div>
              </v-card-title>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import CategoryService from "@/services/category.services.js";
import DraftService from "@/services/draft.services.js";

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      resultArray: Boolean,
      loading: true, // Added loading state
      timeout: 2000,
      show1: false,
      show2: true,
      categories: [],
      userDrafts: [],
    };
  },

  computed: {
    // userDrafts() {
    //   return this.$store.getters["category/getDraftedListing"];
    // },

    loggedInUser() {
      return this.$store.state.auth.user;
    },

    user() {
      return this.loggedInUser || {};
    },
  },

  created() {
    this.getCategories();
    this.getUserDrafts();
  },

  methods: {
    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    getCategories() {
      this.loading = true;
      CategoryService.onlyCategories().then(
        (response) => {
          if (response.status === 200) {
            this.categories = response.data.data;
          } else {
            this.categories = [];
          }
          this.loading = false;
        },
        (error) => {
          this.categories = [];
          this.loading = false;
          console.error(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    getUserDrafts() {
      DraftService.userDrafts(this.user._id).then(
        (response) => {
          if (response.status === 200) {
            this.userDrafts = response.data.data;
            console.log("draft", this.userDrafts);
          } else {
            this.userDrafts = [];
          }
          this.loading = false;
        },
        (error) => {
          this.userDrafts = [];
          this.loading = false;
          console.error(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    selectCategory(category) {
      this.$store.dispatch("category/setCategory", category);
      this.$store.dispatch("category/clearDraft");
      const encodedCategoryName = encodeURIComponent(category.name);
      // if ( encodedCategoryName == 'Property'){
      this.$router.push(`/create-listing/${encodedCategoryName}`);
      // }
    },

    selectDraftAndNavigate(draft) {
      this.$store.dispatch("category/setCategory", draft.category);
      this.$store.dispatch("category/setDraft", draft);
      const encodedCategoryName = encodeURIComponent(draft.category.name);
      this.$router.push(`/create-listing/${encodedCategoryName}`);
    },

    deleteDraft(draft) {
      // console.log('dd', draft);
      DraftService.delete(draft).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", response.data.message);
            this.userDrafts.splice(this.editedIndex, 1);
          } else {
            this.$store.dispatch("alert/error", response.data.message);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
  },
};
</script>

<style scoped>
/* Ensuring buttons and elements maintain transparent backgrounds */
.v-btn::before {
  background-color: transparent;
}

/* Style for main content area */
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
