<template>
  <div>
    <v-app-bar class="text-center" :elevation="1">
      <!-- Clickable Logo -->
      <v-img
        :src="require('@/assets/suuq.logo.svg')"
        alt="SUUQ Logo"
        class="ml-8"
        @click="logoRoute"
        cover
        max-width="100"
      />
    </v-app-bar>

    <div class="d-flex align-center justify-center" style="height: 90vh">
      <v-card elevation="0" width="400" class="mx-auto">
        <v-card-title class="text-center mb-5">
          Create an account
          <!-- <div class="mt-3 text-caption">
                <span class="text-grey"> Enter your details to register with us </span>
            </div> -->
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent class="ma-3">
            <v-text-field
              v-model="editedItem.fullName"
              :rules="[rules.fullName]"
              name="fullName"
              label="Name"
              variant="outlined"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.email"
              :rules="[rules.email]"
              name="email"
              label="Email"
              variant="outlined"
              class="mt-1"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.phoneNumber"
              :rules="[rules.phonenumber]"
              name="phone"
              label="Phone Number"
              variant="outlined"
              class="mt-1"
            ></v-text-field>

            <v-text-field
              v-model="editedItem.password"
              :appendInnerIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Password"
              hint="At least 8 characters"
              counter
              variant="outlined"
              @click:append-inner="show1 = !show1"
            ></v-text-field>

            <v-btn
              :loading="loading"
              block
              color="grey-lighten-2"
              size="large"
              type="submit"
              elevation="0"
              class="mt-2"
              @click="save"
            >
              <span class="text-white"> Register </span>
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
  <script>
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      show1: false,
      show2: false,
      user: "",
      rules: {
        email: (value) => !!value || "email required",
        fullName: (value) => !!value || "first name required",
        lastname: (value) => !!value || "last name required",
        phonenumber: (value) => !!value || "phone number required",
      },
      loading: false,
      editedItem: {
        fullName: "",
        lastname: "",
        phone: "",
        email: "",
        password: "",
      },
      defaultItem: {
        fullName: "",
        lastname: "",
        phone: "",
        email: "",
        password: "",
      },
    };
  },

  computed: {
    userResetEmail() {
      return localStorage.getItem("user_reset_email");
    },
  },

  methods: {
    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    async validate() {
      this.valid = await this.$refs.form.validate();
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    formatPhoneNumber(phoneNumber) {
      const pattern = /^(0|254|\+254)([1-9]\d{8})$/;
      if (pattern.test(phoneNumber)) {
        if (phoneNumber.startsWith("0")) {
          return "+254" + phoneNumber.slice(1);
        } else {
          return "+254" + phoneNumber.slice(-9);
        }
      } else if (/^[71]/.test(phoneNumber)) {
        return "+254" + phoneNumber;
      } else {
        return phoneNumber;
      }
    },

    async save() {
      this.loading = true;
      // this.editedItem.phoneNumber = this.formatPhoneNumber(this.editedItem.phoneNumber);
      this.editedItem.roleId = 3;
      AuthService.selfRegister(this.editedItem).then(
        (response) => {
          if (response.status == 201) {
            this.user = response.data;
            localStorage.setItem("password_reset_email", this.editedItem.email);
            this.$store.dispatch("alert/success", response.data.message);
            this.$router.push(this.$route.query.redirect || "/verify-user");
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error);
          }
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
  },
};
</script>
  