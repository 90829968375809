import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  debug: true, 
});

export const subscribeToNotifications = (userId, callback) => {
  const channel = pusher.subscribe(`${userId}`);

  channel.bind(`${userId}`, (data) => {
    callback(data);
  });
};


