// store/modules/map.module.js
const state = {
  selectedLocation: null,
}

const mutations = {
  setSelectedLocation(state, location) {
    state.selectedLocation = location;
  },
}

const actions = {
  updateSelectedLocation({ commit }, location) {
    commit('setSelectedLocation', location);
    console.log('setSelectedLocation', location);
  },
}

const getters = {
  getSelectedLocation(state) {
    return state.selectedLocation;
  },
}

export const map = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
