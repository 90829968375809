import Pusher from 'pusher-js';

const pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: true,
  debug: true, 
});

export const subscribeToSocialAuth = (callback) => {
  const channel = pusher.subscribe('social-auth');

  channel.bind('social-auth', (data) => {
    callback(data);
  });
};


