<template>
  <div>
    <v-app-bar class="text-center" :elevation="1">
      <!-- Clickable Logo -->
      <v-img
        :src="require('@/assets/suuq.logo.svg')"
        alt="SUUQ Logo"
        @click="logoRoute"
      />
    </v-app-bar>

    <v-row class="main-content">
      <v-col cols="12" class="mx-auto">
        <v-card elevation="0" :width="this.$vuetify.display.mobile ? 400 : 700" class="mx-auto mt-n1">
          <v-card-title class="mb-1">
            <div>
              Notifications
              <div class="text-caption text-grey mt-n1">
                View what's new today!
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <!-- Display multiple notifications dynamically here -->
            <div v-if="myNotifications.length > 0">
              <v-alert
                v-for="(notification, index) in myNotifications"
                :key="notification.id"
                class="mt-1"
                :type="notification.statusCode === 200 ? 'success' : 'error'"
                variant="tonal"
                @click="markAsRead(notification.id, index)"
              >
                {{ notification.message }}
              </v-alert>
            </div>
            <div v-else class="text-center">
              <span class="text-center text-error">No Notifications</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { subscribeToNotifications } from "@/services/my.notifications.pusher.js";
import NotificationService from "@/services/notification.service.js";

export default {
  data() {
    return {
      myNotifications: [],
    };
  },
  computed: {
    loggedInUser() {
      return this.$store.state.auth.user;
    },
  },

  mounted() {
    if (this.loggedInUser && this.loggedInUser._id) {
      subscribeToNotifications(this.loggedInUser._id, (data) => {
        console.log("Received data:", data);
        if (Array.isArray(data.data)) {
          this.myNotifications = [...data.data, ...this.myNotifications];
          this.$store.dispatch("notification/setUnreadNotificationCount", this.myNotifications.length);
        } else {
          this.myNotifications.unshift(data.data);
        }
      });
    }
  },

  created() {
    if (this.loggedInUser && this.loggedInUser._id) {
      this.getNotifications();
    }
  },

  methods: {
    logoRoute() {
      this.$router.push("/");
    },

    getNotifications() {
      return NotificationService.userNotifications(this.loggedInUser._id).then(
        (response) => {
          if (response.status == 200) {
            this.myNotifications = response.data.data;
            console.log("this.myNotifications", this.myNotifications);
            this.$store.dispatch("notification/setUnreadNotificationCount", this.myNotifications.length);
            this.loading = false;
          } else {
            this.myNotifications = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.myNotifications = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    markAsRead(id, index) {
      return NotificationService.markAsRead(id).then(
        (response) => {
          if (response.status == 200) {
            // Remove the notification from the array
            this.myNotifications.splice(index, 1);
          }
        },
        (error) => {
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },
  },
};
</script>

<style scoped>
/* Ensuring buttons and elements maintain transparent backgrounds */
.v-btn::before {
  background-color: transparent;
}

/* Style for main content area */
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
