<template>
  <div class="">
    <v-app>
      <!-- app bar -->
      <v-app-bar
        :elevation="0"
        v-if="!this.$vuetify.display.mobile"
        color="grey-lighten-4 px-11"
        variant="outlined"
      >
        <!-- Clickable Logo -->
        <v-img
          :src="require('@/assets/suuq.logo.svg')"
          alt="SUUQ Logo"
          @click="logoRoute"
        />

        <v-spacer></v-spacer>
        <div v-for="item in ecosystem" :key="item" class="mx-5 text-grey">
          <v-btn @click="routeTo(item.route)" density="compact" stacked>
            <v-icon size="x-small">{{ item.icon }}</v-icon>
            <span class="text-caption">{{ item.text }}</span>
          </v-btn>
        </div>

        <!-- Login or Signup button for larger screens -->
        <div v-if="$vuetify.display.mdAndUp">
          <div v-if="!loggedInUser">
            <v-btn
              variant="text"
              class="text-caption mr-10"
              @click="loginOrSignup"
            >
              Login or Signup
            </v-btn>
          </div>
          <div v-else>
            <v-menu open-on-hover>
              <template v-slot:activator="{ props }">
                <v-avatar
                  v-bind="props"
                  size="small"
                  class="mr-8"
                  color="error"
                >
                </v-avatar>
              </template>
              <v-list>
                <v-list-item @click="handleLogout">
                  <v-list-item-title>
                    <div class="pa-1">
                      <v-icon
                        size="small"
                        color="blue"
                        icon="mdi-logout-variant"
                      >
                      </v-icon>
                      Logout
                    </div>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>

        <v-btn
          @click="placeAd"
          color="error"
          class="error text-caption mr-16"
          variant="flat"
        >
          Place Your Ad</v-btn
        >
      </v-app-bar>

      <!--small screen search -->
      <div class="mx-1 mt-1" v-if="this.$vuetify.display.mobile">
        <v-text-field
          v-model="searchQuery"
          variant="outlined"
          rounded="xl"
          placeholder="search for anything"
        >
          <template v-slot:append-inner>
            <v-icon color="error">mdi-magnify</v-icon>
          </template>
          <template v-slot:prepend-inner>
            <v-icon color="error">mdi-home</v-icon>
          </template>
        </v-text-field>
      </div>
      <!-- end of search -->

      <!-- --------------------------- MAIN SECTION ---------------------------- -->

      <!-- main content here -->
      <v-main class="mx-16 ma-1 mt-2">
        <v-row class="mx-5">
          <v-col cols="12" v-if="$vuetify.display.mdAndUp">
            <v-img max-height="280" src="../assets/web_ad.jpeg"></v-img>
          </v-col>

          <!-- ----------CATEGORY INDICATOR----------- -->
          <v-col
            cols="12"
            class="mb-n2"
            :class="this.$vuetify.display.mobile ? 'mx-n16' : ''"
          >
            <v-icon
              color="error"
              size="x-small"
              icon="mdi-chevron-right"
            ></v-icon>
            <v-btn
              variant="text"
              @click="backToSearch"
              class="text-caption text-primary"
            >
              Back to search
            </v-btn>
            <v-icon
              color="error"
              size="x-small"
              icon="mdi-chevron-right"
              class="ml-n2"
            ></v-icon>
            <span class="text-caption">
              {{ selectedItem.category.name }}
            </span>
            <v-icon
              color="error"
              size="x-small"
              icon="mdi-chevron-right"
            ></v-icon>
            <span class="text-caption">
              {{ selectedItem.title }}
            </span>
          </v-col>

          <!-- ----- PRODUCTS ------ -->
          <v-col cols="12">
            <v-row
              class="ma-2"
              :class="this.$vuetify.display.mobile ? 'mx-n16' : ''"
            >
              <!-- -------VIDEO----- -->
              <!-- <v-col cols="12">
                <v-card class="mx-auto">
                  <v-img
                    class="align-end text-white"
                    height="500"
                    src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
                    cover
                  >
                    <v-card-title>Top 10 Australian beaches</v-card-title>
                  </v-img>
                </v-card>
              </v-col> -->
              <!--  end video  -->
              <v-col
                cols="12"
                md="6"
                v-for="(item, i) in selectedItem.files"
                :key="i"
              >
                <v-card
                  :class="
                    this.$vuetify.display.mobile
                      ? 'rounded-lg mx-n9'
                      : 'rounded-lg'
                  "
                  color="grey-lighten-2"
                >
                  <v-col cols="12">
                    <v-img
                      height="400"
                      :src="`data:${item?.mime_type};base64,${item?.base64_data}`"
                      cover
                      class="rounded-lg"
                      aspect-ratio="16/9"
                    >
                    </v-img>
                  </v-col>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="8" class="ml-n5">
                    <v-card
                      :class="this.$vuetify.display.mobile ? 'mr-n9' : ''"
                    >
                      <template v-slot:title>
                        <div class="d-flex">
                          <span class="font-weight-black"
                            >{{ "USD" + " " }} {{ selectedItem.price }}</span
                          >
                        </div>
                      </template>
                      <template v-slot:append>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-btn
                              color="blue"
                              variant="outlined"
                              prepend-icon="mdi-message"
                              class="mr-2"
                              @click="chatUser"
                            >
                              Chat
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-btn
                              color="success"
                              variant="outlined"
                              prepend-icon="mdi-heart-outline"
                              class=""
                              @click="close"
                            >
                              Favorite
                            </v-btn>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-btn
                              color="error"
                              variant="outlined"
                              prepend-icon="mdi-share-variant-outline"
                              :class="
                                this.$vuetify.display.mobile ? '' : 'ml-2'
                              "
                              @click="close"
                            >
                              Share
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:subtitle>
                        <v-icon
                          color="error"
                          class=""
                          icon="mdi-map-marker"
                        ></v-icon>
                        <span class="text-black ml-1"
                          >{{ selectedItem.city.title }},
                          {{ selectedItem.city.country.name }}</span
                        >
                      </template>
                      <v-divider></v-divider>
                      <v-card-text class="bg-surface-light pt-4">
                        <div class="ml-2">
                          <span class="font-weight-black">
                            {{ selectedItem.title }}
                          </span>
                        </div>
                        <v-list class="bg-surface-light ml-n2">
                          <v-list-item v-if="selectedItem.activeRelation.brand">
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Brand:</strong>
                            {{ selectedItem.activeRelation.brand }}
                          </v-list-item>
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.age &&
                              selectedItem.activeRelation.age !== 'null'
                            "
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Age (Years):</strong>
                            {{ selectedItem.activeRelation.age }}
                          </v-list-item>
                          <v-list-item
                            v-if="selectedItem.activeRelation.condition"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Condition:</strong>
                            {{ selectedItem.activeRelation.condition }}
                          </v-list-item>
                          <v-list-item v-if="selectedItem.activeRelation.usage">
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Usage:</strong>
                            {{ selectedItem.activeRelation.usage }}
                          </v-list-item>
                          <v-list-item
                            v-if="selectedItem.activeRelation.warranty"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Warranty(years):</strong>
                            {{ selectedItem.activeRelation.warranty }}
                          </v-list-item>

                          <!-- Property Type -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.propertyType"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Property Type:</strong>
                            {{ selectedItem.activeRelation.propertyType }}
                          </v-list-item>

                          <v-list-item
                            v-if="selectedItem.activeRelation.occupancyStatus"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Occupancy Status:</strong>
                            {{ selectedItem.activeRelation.occupancyStatus }}
                          </v-list-item>

                          <v-list-item
                            v-if="selectedItem.activeRelation.isFurnished"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Furnishing:</strong>
                            {{
                              selectedItem.activeRelation.isFurnished
                                ? "Furnished"
                                : "Unfurnished"
                            }}
                          </v-list-item>

                          <!-- Bathrooms -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.bathrooms"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Bathrooms:</strong>
                            {{ selectedItem.activeRelation.bathrooms }}
                          </v-list-item>

                          <!-- Bedrooms -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.bedrooms"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Bedrooms:</strong>
                            {{ selectedItem.activeRelation.bedrooms }}
                          </v-list-item>

                          <!-- Maintenance Fee -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.maintenanceFee"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Maintenance Fee:</strong>
                            {{ selectedItem.activeRelation.maintenanceFee }}
                          </v-list-item>

                          <!-- Minimum Contract Period -->
                          <v-list-item
                            v-if="
                              selectedItem.activeRelation.minimumContractPeriod
                            "
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Minimum Contract Period (years):</strong>
                            {{
                              selectedItem.activeRelation.minimumContractPeriod
                            }}
                          </v-list-item>

                          <!-- Notice Period -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.noticePeriod"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Notice Period (months):</strong>
                            {{ selectedItem.activeRelation.noticePeriod }}
                          </v-list-item>

                          <!-- Price Per Year -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.pricePerYear"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Price Per Year:</strong>
                            {{ selectedItem.activeRelation.pricePerYear }}
                          </v-list-item>
                          <!-- Rent Paid -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.rentPaid"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Rent Paid:</strong>
                            {{ selectedItem.activeRelation.rentPaid }}
                          </v-list-item>

                          <!-- Size -->
                          <v-list-item v-if="selectedItem.activeRelation.size">
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Size:</strong>
                            {{ selectedItem.activeRelation.size }}
                          </v-list-item>

                          <!-- Make/Model -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.makeModel"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Make/Model:</strong>
                            {{ selectedItem.activeRelation.makeModel }}
                          </v-list-item>

                          <!-- Body Type -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.bodyType"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Body Type:</strong>
                            {{ selectedItem.activeRelation.bodyType }}
                          </v-list-item>

                          <!-- Is Insured -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.isInsured"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Insurance:</strong>
                            {{ selectedItem.activeRelation.isInsured }}
                          </v-list-item>

                          <!-- Kilometers -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.kilometers"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Mileage (Kilometers):</strong>
                            {{ selectedItem.activeRelation.kilometers }}
                          </v-list-item>

                          <!-- Price -->
                          <v-list-item v-if="selectedItem.activeRelation.price">
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Price:</strong>
                            {{ selectedItem.activeRelation.price }}
                          </v-list-item>

                          <!-- Specs -->
                          <v-list-item v-if="selectedItem.activeRelation.specs">
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Specs:</strong>
                            {{ selectedItem.activeRelation.specs }}
                          </v-list-item>

                          <!-- Year -->
                          <v-list-item v-if="selectedItem.activeRelation.year">
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Year:</strong>
                            {{ selectedItem.activeRelation.year }}
                          </v-list-item>

                          <!-- Trim -->
                          <v-list-item v-if="selectedItem.activeRelation.trim">
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Trim:</strong>
                            {{ selectedItem.activeRelation.trim }}
                          </v-list-item>

                          <!-- Phone Number -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.phoneNumber"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Phone Number:</strong>
                            {{ selectedItem.activeRelation.phoneNumber }}
                          </v-list-item>

                          <!-- Last Updated (updated_at) -->
                          <v-list-item
                            v-if="selectedItem.activeRelation.updated_at"
                          >
                            <v-list-item-icon>
                              <v-icon size="x-large">mdi-square-small</v-icon>
                            </v-list-item-icon>
                            <strong>Last Updated:</strong>
                            {{
                              formatDate(selectedItem.activeRelation.updated_at)
                            }}
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>

                    <!-- -------DESCRIPTION------- -->
                    <v-card
                      :class="
                        this.$vuetify.display.mobile ? 'mr-n9 mt-2' : 'mt-2'
                      "
                      v-if="selectedItem.activeRelation.description"
                    >
                      <template v-slot:title>
                        <span class="font-weight-black">DESCRIPTION</span>
                      </template>
                      <v-card-text class="bg-surface-light pt-4">
                        {{ selectedItem.activeRelation.description }}
                      </v-card-text>
                    </v-card>

                    <!-- -------AMENITIES------- -->
                    <v-card
                      :class="
                        this.$vuetify.display.mobile ? 'mr-n9 mt-2' : 'mt-2'
                      "
                      v-if="selectedItem.category.slug === 'property'"
                    >
                      <template v-slot:title>
                        <span class="font-weight-black">Amenities</span>
                      </template>

                      <v-card-text class="bg-surface-light pt-4">
                        <v-row>
                          <!-- Loop over the amenities and display them dynamically -->
                          <v-col
                            v-for="(amenity, index) in filteredAmenities"
                            :key="index"
                            cols="12"
                            md="3"
                          >
                            <v-card class="rounded-lg pa-7">
                              <v-img
                                height=""
                                :src="require(`@/assets/${amenity.image}`)"
                                cover
                                class="rounded-lg"
                              >
                              </v-img>
                              <div
                                class="mt-2 text-center font-weight-black text-caption"
                              >
                                {{ amenity.label }}
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <!-- -------MAP VIEW ------- -->
                    <v-card
                      :class="
                        this.$vuetify.display.mobile ? 'mr-n9 mt-2' : 'mt-2'
                      "
                    >
                      <template v-slot:title>
                        <div class="font-weight-black">Map View</div>
                        <div class="text-black text-caption">
                          {{ selectedItem.city.title }},
                          {{ selectedItem.city.country.name }}
                        </div>
                      </template>
                      <v-card-text class="bg-surface-light pt-4">
                        <GoogleMap :cities="selectedItem.city"></GoogleMap>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <!-- ---- USER PROFILE ---- -->
                  <v-col cols="12" md="4">
                    <v-card
                      :class="this.$vuetify.display.mobile ? 'mx-n8' : ''"
                    >
                      <v-card-text>
                        <v-row>
                          <v-col cols="4">
                            <v-card class="rounded-lg" color="grey-lighten-2">
                              <v-card-item>
                                <v-row>
                                  <v-col cols="12">
                                    <v-img
                                      height="80"
                                      src="@/assets/profile.png"
                                      cover
                                      class="rounded-lg"
                                    >
                                    </v-img>
                                  </v-col>
                                </v-row>
                              </v-card-item>
                            </v-card>
                          </v-col>
                          <v-col cols="8">
                            <div>
                              <span class="font-weight-black">
                                {{ selectedItem.user?.fullName }}</span
                              >
                              <div class="">
                                <span class="font-weight-black text-caption">
                                  EMAIL:
                                </span>
                                {{ selectedItem.user?.email }}
                              </div>
                              <div class="">
                                <span class="font-weight-black text-caption">
                                  Tel:
                                </span>
                                {{ selectedItem.user?.phoneNumber }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          variant="outlined"
                          prepend-icon="mdi-email"
                          class="ml-4"
                          @click="close"
                        >
                          Email
                        </v-btn>
                        <v-btn
                          color="error"
                          variant="outlined"
                          prepend-icon="mdi-phone"
                          class="ml-2"
                          @click="close"
                        >
                          Call
                        </v-btn>

                        <v-btn
                          color="success"
                          variant="outlined"
                          prepend-icon="mdi-whatsapp"
                          class="ml-2"
                          @click="close"
                        >
                          Whatsapp
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
      <!-- end of main content-->

      <div v-if="!this.$vuetify.display.mobile">
        <FooterComponent></FooterComponent>
      </div>
    </v-app>
    <!-- small screen bottom nav -->
    <v-footer v-if="this.$vuetify.display.mobile" class="hidden-md-and-up" app>
      <!-- bottom nav -->
      <v-bottom-navigation class="mb-n5 pa-2 text-grey">
        <div class="mb-4 pa-1">
          <v-list class="d-flex mx-auto justify-space-between">
            <v-list-item
              v-for="(item, index) in bottomNav"
              :key="index"
              :value="item.name"
              :to="item.route"
              :class="['mx-1', 'mt-n4', 'pb-2', 'text-grey']"
              @click="toggle(item)"
            >
              <template v-slot:title>
                <div></div>
                <div class="text-center">
                  <v-icon size="x-small">{{ item.icon }}</v-icon>
                </div>
                <div class="text-caption pb-1">{{ item.name }}</div>
              </template>
            </v-list-item>
          </v-list>
        </div>
      </v-bottom-navigation>
      <!-- menu dialog -->
      <v-dialog v-model="menuActive" width="auto" class="ma-2">
        <v-card class="ml-n2 pa-3" width="350">
          <v-row no-gutters>
            <v-col cols="12" v-if="!loggedInUser">
              <v-btn
                variant="tonal"
                color="error"
                class="text-caption mr-10"
                @click="loginOrSignup"
                block
              >
                Login or Signup
              </v-btn>

              <v-dialog v-model="showLoginDialog" width="380">
                <v-card max-width="900"> <auth-component /> </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" v-else>
              <div class="text-center">
                <v-avatar color="error" size="70"></v-avatar>
                <div class="my-4 font-weight-black text-caption">
                  {{ loggedInUser.fullName }}
                  <div>{{ loggedInUser.email }}</div>
                </div>
              </div>
              <v-card-actions color="blue">
                <v-btn
                  color="blue"
                  @click="handleLogout"
                  prepend-icon="mdi-logout-variant"
                  variant="outlined"
                  block
                >
                  Logout
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>

          <div></div>
        </v-card>
      </v-dialog>
    </v-footer>
    <!-- end small screen bottom nav -->
  </div>
</template>
<script>
import FooterComponent from "@/components/footer/FooterComponent.vue";
import GoogleMap from "@/components/GoogleMapComponent.vue";
import CategoryService from "@/services/category.services";
import ListingService from "../services/listing.service.js";
import ChatService from "@/services/chat.service.js";
import moment from "moment";

export default {
  name: "HomeComponent",
  components: {
    FooterComponent,
    GoogleMap,
  },

  data: () => ({
    value: 1,
    ecosystem: [
      {
        text: "Notification",
        route: "/notification",
        icon: "mdi-bell-outline",
      },
      {
        text: "My Searches",
        route: "/my-search",
        icon: "mdi-feature-search-outline",
      },
      {
        text: "Chats",
        route: "/chats",
        icon: "mdi-message-text",
      },
      {
        text: "My Ads",
        route: "/my-ads",
        icon: "mdi-view-list-outline",
      },
    ],
    bottomNav: [
      {
        name: "Home",
        route: "/",
        icon: "mdi-home",
      },
      {
        name: "Search",
        route: "",
        icon: "mdi-magnify",
        menu: "search",
      },
      {
        name: "Post an Ad",
        route: "/place-ad",
        icon: "mdi-plus-circle-outline",
      },
      {
        name: "Chats",
        route: "/chats",
        icon: "mdi-message-text",
      },
      {
        name: "menu",
        route: "",
        icon: "mdi-menu",
        menu: "menu",
      },
    ],

    fetureCategories: [
      {
        icon: "motors",
        title: "Motors",
        subcategories: [
          { title: "Cars" },
          { title: "Motorcycles" },
          { title: "Auto Accessories & Parts" },
          { title: "Heavy Vehicles" },
        ],
        allInText: "All in Motors",
      },
      {
        icon: "property-for-rent",
        title: "Property for Rent",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "Rooms For Rent" },
          { title: "Monthly Short Term" },
        ],
        allInText: "All in Property for Rent",
      },
      {
        icon: "property-for-sale",
        title: "Property for Sale",
        subcategories: [
          { title: "Residential" },
          { title: "Commercial" },
          { title: "New Projects" },
          { title: "Off-Plan" },
        ],
        allInText: "All in Property for Sale",
      },
      {
        icon: "classified",
        title: "Classifieds",
        subcategories: [
          { title: "Electronics" },
          { title: "Computers & Networking" },
          { title: "Clothing & Accessories" },
          { title: "Jewelry & Watches" },
        ],
        allInText: "All in Classifieds",
      },
      {
        icon: "jobs",
        title: "Jobs",
        subcategories: [
          { title: "Accounting / Finance" },
          { title: "Engineering" },
          { title: "Sales / Business Development" },
          { title: "Secretarial / Front Office" },
        ],
        allInText: "All in Jobs",
      },
      {
        icon: "community",
        title: "Community",
        subcategories: [
          { title: "Freelancers" },
          { title: "Home Maintenance" },
          { title: "Other Services" },
          { title: "Tutors & Classes" },
        ],
        allInText: "All in Community",
      },
      {
        icon: "business-industrial",
        title: "Business & Industrial",
        subcategories: [
          { title: "Businesses for Sale" },
          { title: "Construction" },
          { title: "Food & Beverage" },
          { title: "Industrial Supplies" },
        ],
        allInText: "All in Business & Industrial",
      },
      {
        icon: "home-appliances",
        title: "Home Appliances",
        subcategories: [
          { title: "Large Appliances / White Goods" },
          { title: "Small Kitchen Appliances" },
          { title: "Outdoor Appliances" },
          { title: "Small Bathroom Appliances" },
        ],
        allInText: "All in Home Appliances",
      },
      {
        icon: "furniture-home-garden",
        title: "Furniture, Home & Garden",
        subcategories: [
          { title: "Furniture" },
          { title: "Home Accessories" },
          { title: "Garden & Outdoor" },
          { title: "Lighting & Fans" },
        ],
        allInText: "All in Furniture, Home & Garden",
      },
      {
        icon: "mobile-phones-pdas",
        title: "Mobile Phones & Tablets",
        subcategories: [
          { title: "Mobile Phones" },
          { title: "Mobile Phone & Tablet Accessories" },
          { title: "Tablets" },
          { title: "Other Mobile Phones & Tablets" },
        ],
        allInText: "All in Mobile Phones & Tablets",
      },
    ],

    amenities: [
      { key: "balcony", label: "Balcony", image: "balcony.svg" },
      {
        key: "centralACHeating",
        label: "Central A/C & Heating",
        image: "central_ac_and_heating.svg",
      },
      {
        key: "conciergeService",
        label: "Concierge Service",
        image: "concierge_service.svg",
      },
      {
        key: "maidsRoom",
        label: "Maid's Room",
        image: "maintenance_staff.svg",
      },
      { key: "petsAllowed", label: "Pets Allowed", image: "pets_allowed.svg" },
      {
        key: "privateGarden",
        label: "Private Garden",
        image: "private_garden.svg",
      },
      { key: "privatePool", label: "Private Pool", image: "swimming_pool.svg" },
      { key: "studyRoom", label: "Study Room", image: "study_room.svg" },
      { key: "privateGym", label: "Private Gym", image: "private_gym.svg" },
      {
        key: "privateJacuzzi",
        label: "Private Jacuzzi",
        image: "private_jacuzzi.svg",
      },
      { key: "sharedPool", label: "Shared Pool", image: "shared_pool.svg" },
      { key: "sharedSpa", label: "Shared Spa", image: "shared_spa.svg" },
      { key: "sharedGym", label: "Shared Gym", image: "shared_gym.svg" },
      { key: "security", label: "Security", image: "security.svg" },
      {
        key: "maidService",
        label: "Maid's Service",
        image: "maid_service.svg",
      },
      {
        key: "coveredParking",
        label: "Covered Parking",
        image: "covered_parking.svg",
      },
      {
        key: "builtInWardrobes",
        label: "Built-in Wardrobes",
        image: "built_in_wardrobes.svg",
      },
      {
        key: "walkInCloset",
        label: "Walk-in Closet",
        image: "walk_in_closet.svg",
      },
      {
        key: "builtInKitchenAppliances",
        label: "Built-in Kitchen Appliances",
        image: "kitchen_appliances.svg",
      },
      {
        key: "viewOfWater",
        label: "View of Water",
        image: "view_of_water.svg",
      },
      {
        key: "viewOfLandmark",
        label: "View of Landmark",
        image: "view_of_landmark.svg",
      },
      {
        key: "doubleGlazedWindows",
        label: "Double Glazed Windows",
        image: "double_glazed_windows.svg",
      },
      {
        key: "dayCareCenter",
        label: "Day Care Center",
        image: "day_care_center.svg",
      },
      {
        key: "electricityBackup",
        label: "Electricity Backup",
        image: "electricity_backup.svg",
      },
      {
        key: "firstAidMedicalCenter",
        label: "First Aid Medical Center",
        image: "first_aid_center.svg",
      },
      {
        key: "serviceElevators",
        label: "Service Elevators",
        image: "service_elevators.svg",
      },
      { key: "prayerRoom", label: "Prayer Room", image: "prayer_room.svg" },
      { key: "laundryRoom", label: "Laundry Room", image: "laundry_room.svg" },
      {
        key: "broadbandInternet",
        label: "Broadband Internet",
        image: "broadband_internet.svg",
      },
      {
        key: "satelliteCableTV",
        label: "Satellite / Cable TV",
        image: "satellite_cable_tv.svg",
      },
      {
        key: "businessCenter",
        label: "Business Center",
        image: "business_center.svg",
      },
      { key: "intercom", label: "Intercom", image: "intercom.svg" },
      { key: "atmFacility", label: "ATM Facility", image: "atm_facility.svg" },
      {
        key: "kidsPlayArea",
        label: "Kids Play Area",
        image: "kids_play_area.svg",
      },
      {
        key: "receptionWaitingRoom",
        label: "Reception / Waiting Room",
        image: "reception_room.svg",
      },
      {
        key: "maintenanceStaff",
        label: "Maintenance Staff",
        image: "maintenance_staff.svg",
      },
      {
        key: "cctvSecurity",
        label: "CCTV Security",
        image: "cctv_security.svg",
      },
      {
        key: "cafeteriaOrCanteen",
        label: "Cafeteria or Canteen",
        image: "cafeteria.svg",
      },
      {
        key: "sharedKitchen",
        label: "Shared Kitchen",
        image: "shared_kitchen.svg",
      },
      {
        key: "facilitiesForDisabled",
        label: "Facilities for Disabled",
        image: "disabled_facilities.svg",
      },
      {
        key: "storageAreas",
        label: "Storage Areas",
        image: "storage_areas.svg",
      },
      {
        key: "cleaningServices",
        label: "Cleaning Services",
        image: "cleaning_services.svg",
      },
      {
        key: "barbequeArea",
        label: "Barbeque Area",
        image: "barbeque_area.svg",
      },
      {
        key: "lobbyInBuilding",
        label: "Lobby in Building",
        image: "lobby_in_building.svg",
      },
      {
        key: "wasteDisposal",
        label: "Waste Disposal",
        image: "waste_disposal.svg",
      },
    ],

    chatDetail: {
      vendor_id: "",
      customer_id: "",
    },

    menuActive: false,
    searchActive: false,
    categories: {},
    listings: [],
    chat: [],
    searchQuery: "",
  }),
  computed: {
    loggedInUser() {
      return this.$store.state.auth.user;
    },

    selectedItem() {
      return this.$store.getters["category/getSelectedItem"];
    },

    user() {
      return this.loggedInUser || {};
    },

    groupedListings() {
      return this.listings.reduce((grouped, listing) => {
        const categorySlug = listing.category.slug;
        if (!grouped[categorySlug]) {
          grouped[categorySlug] = [];
        }
        grouped[categorySlug].push(listing);
        return grouped;
      }, {});
    },

    filteredListings() {
      return this.listings.filter((listing) => {
        const searchQueryLower = this.searchQuery.toLowerCase();
        return (
          listing.title.toLowerCase().includes(searchQueryLower) ||
          listing.description.toLowerCase().includes(searchQueryLower)
        );
      });
    },

    filteredAmenities() {
      return this.amenities.filter(
        (amenity) => this.selectedItem.activeRelation[amenity.key]
      );
    },
  },

  created() {
    this.getCategories();
    this.getlistings();
  },

  methods: {
    logoRoute() {
      this.$router.push(this.$route.query.redirect || "/");
    },
    getCategoryName(slug) {
      const category = this.categories.find((cat) => cat.slug === slug);
      return category ? category.title : "Unknown Category";
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/login");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    backToSearch() {
      this.$router.push("/");
    },

    formatDate(dateString) {
      return moment(dateString).format("DD-MM-YYYY");
    },

    getCategories() {
      return CategoryService.getCategories().then(
        (response) => {
          if (response.status == 200) {
            this.categories = response.data.data;
            this.loading = false;
          } else {
            this.categories = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.categories = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    formatKey(key) {
      // Convert camelCase to a more human-readable format
      return key
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },

    getlistings() {
      return ListingService.getApprovedOpenListing().then(
        (response) => {
          if (response.status == 200) {
            this.listings = response.data.data;
            // console.log("the list", this.listings);
            this.loading = false;
          } else {
            this.listings = [];
            this.loading = false;
            console.log(response.data.console.error);
          }
        },
        (error) => {
          this.listings = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          this.$store.dispatch("alert/error", error.message);
        }
      );
    },

    chatUser() {
      if (this.loggedInUser) {
        this.chatDetail.vendor_id = this.loggedInUser._id;
        this.chatDetail.customer_id = this.selectedItem.user._id;
        return ChatService.getOrCreateChat(this.chatDetail).then(
          (response) => {
            if (response.status == 200) {
              this.chat = response.data.data;
              this.$store.dispatch("chat/setCreatedChat", this.chat);
              this.$router.push("/chats");
              this.$store.dispatch("alert/success", response.data.message);
              console.log("the list", this.chat);
              this.loading = false;
            }
          },
          (error) => {
            this.chat = [];
            console.log(error);
            this.$store.dispatch("alert/error", error.message);
          }
        );
      } else {
        this.$router.push("/");
        this.$store.dispatch("alert/error", "Login to chat");
      }
    },

    async getlistingFile(gridfs_id) {
      return ListingService.getListingFile(gridfs_id).then(
        (response) => {
          if (response.status == 200) {
            const mimeType = response.headers["content-type"]; // Get the MIME type from the response
            const base64Image = this.convertToBase64(response.data, mimeType); // Convert binary data to Base64 with dynamic MIME
            return base64Image;

            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.console.error);
            return null;
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error);
          return null;
        }
      );
    },

    convertToBase64(binaryData, mimeType) {
      // Ensure binary data is a Uint8Array
      const base64String = btoa(
        new Uint8Array(binaryData).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return `data:${mimeType};base64,${base64String}`;
    },

    loginOrSignup() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push("/");
    },

    routeTo(route) {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || route);
    },

    placeAd() {
      !this.loggedInUser
        ? this.$router.push(this.$route.query.redirect || "/auth-list")
        : this.$router.push(this.$route.query.redirect || "/place-ad");
    },

    toggle(item) {
      if ((!item.route && item.menu === "menu") || "search") {
        item.menu === "menu"
          ? (this.menuActive = !this.menuActive)
          : (this.searchActive = !this.searchActive);
      }
    },

    isLoggedIn() {
      return !!localStorage.getItem("token");
    },

    handleLogout() {
      this.$store.commit("logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("resetInactivityTimeout");
      this.$router.push("/");
      this.$store.dispatch("alert/success", "Logout Successfully");
    },

    base64ToFile(base64String, fileType, fileName) {
      const base64Data = base64String.replace(/^data:.+;base64,/, "");
      const byteCharacters = atob(base64Data);
      const byteArray = [...byteCharacters].map((char) => char.charCodeAt(0));
      const blob = new Blob([new Uint8Array(byteArray)], { type: fileType });
      const file = new File([blob], fileName, { type: fileType });
      console.log(file);
      return file;
    },
  },
};
</script>
<style scoped>
.v-input__icon--append .v-icon {
  color: purple;
}

.custom-card {
  background-color: rgba(0, 0, 0, 0.6);
}

.hover-effect {
  background-color: transparent !important;
  transition: color 0.3s ease;
}

.hover-effect:hover {
  background-color: transparent !important;
  color: blue !important;
}

.v-list-item-title {
  color: inherit;
}
</style>
